// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-tad-content-tad-content-brands-jsx": () => import("./../../../src/components/TadContent/TadContentBrands.jsx" /* webpackChunkName: "component---src-components-tad-content-tad-content-brands-jsx" */),
  "component---src-components-tad-content-tad-content-partnerships-jsx": () => import("./../../../src/components/TadContent/TadContentPartnerships.jsx" /* webpackChunkName: "component---src-components-tad-content-tad-content-partnerships-jsx" */),
  "component---src-components-tad-content-tad-content-shops-jsx": () => import("./../../../src/components/TadContent/TadContentShops.jsx" /* webpackChunkName: "component---src-components-tad-content-tad-content-shops-jsx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-the-company-js": () => import("./../../../src/pages/about-the-company.js" /* webpackChunkName: "component---src-pages-about-the-company-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-taddutyfree-js": () => import("./../../../src/pages/taddutyfree.js" /* webpackChunkName: "component---src-pages-taddutyfree-js" */)
}

